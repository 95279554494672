<template>
    <div id="content">
        <div class="container-fluid">
            <div class="box">
            	<div class="row">
            		<div class="col-4 d-flex">
            			<b-input-group :prepend="getTrad('global.licence_key')">
							<b-form-input v-model="licence_key" :disabled="true"></b-form-input>
							<b-input-group-append>
								<b-button @click="copyURL(licence_key)"><font-awesome-icon :icon="['far', 'copy']" /></b-button>
							</b-input-group-append>
						</b-input-group>
            		</div>
					<div class="col-auto ml-auto d-flex">
                		<b-button variant="primary" @click="addLicenceConfig">
                			{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" class="ml-1"/>
		                </b-button>
                	</div>
                </div>
            	<div class="row mt-2" v-for="(token, index) in api_token" :key="index">
            		<template v-if="token.valide">
	            		<div class="col-5">
							<b-form-input
								v-model="token.label"
								:placeholder="$t('global.label')"
							></b-form-input>
	                    </div>

	                    <div class="col-6">
							<b-input-group>
								<b-form-input v-model="token.token" :disabled="true"></b-form-input>
								<b-input-group-append>
									<b-button @click="copyURL(token.token)"><font-awesome-icon :icon="['far', 'copy']" /></b-button>
								</b-input-group-append>
							</b-input-group>
						</div>

						<div class="col-1">
							<button @click="deleteAPI(index)" class="btn btn-secondary ml-auto"><font-awesome-icon :icon="['fal', 'trash-alt']" class="ml-1" /></button>
						</div>
					</template>
            	</div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button v-if="api_token.length > 0" variant="primary" rounded-pill @click="saveConfig"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t('global.enregistrer') }}</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from '@/mixins/User'
import LicenceConfigType from "@/mixins/LicenceConfigType.js"
import Common from '@/assets/js/common'

export default {
    name: 'APIconfigSettings',
    mixins: [User, LicenceConfigType],
   	data () {
		return {
			api_token: [],
            processing: false,
            licence_key: ''
		}
	},
    created() {
        this.init_component()
    },
    methods: {
        async init_component() {
        	this.licence_key = this.getConfig('licence_key')

            this.api_token = []
        	const tokens = await this.getLicenceTokenAPI()

            await Common.asyncForEach(tokens, async (token) => {
	        	this.api_token.push({
	        		label: token.apitoken_label,
	        		token: token.apitoken_token,
	        		valide: true
	        	})
		    })
        },

        async addLicenceConfig() {
        	const token = await this.generateToken('API')
        	this.api_token.push({
        		label: null,
        		token: token,
        		valide: true
        	})
        },

        async saveConfig() {
            this.processing = true
            await this.saveLicenceTokenAPI(this.api_token)
            this.processing = false
            this.init_component()
        },

		async copyURL(mytext) {
			try {
				await navigator.clipboard.writeText(mytext);
			} catch($e) {
				console.error($e);
			}
		},

		async deleteAPI(index) {
			this.api_token[index].valide = false
		}
    }
};
</script>